
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.Pagination__item {
  padding: 0.4rem;
  cursor: pointer;
  list-style: none;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(211, 211, 211);

  &:hover {
    color: var(--color-text);
    border: 1px solid var(--color-text);
  }


}

.Pagination__itemActive {
  background: rgb(185, 226, 255);
  background: radial-gradient(circle,
      rgb(96, 189, 255) 0%,
      var(--color-text) 100%);
  color: white;
  border: 1px solid var(--color-text);

  &:hover {
    color: white;
  }
}

.Pagination__arrow {
  cursor: pointer;
  list-style: none;
  width: 30px;
  height: 30px;
  background-color: rgb(147, 164, 189);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--color-text);
  }
}