
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.banner-cookies {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: var(--color-text);
  z-index: 999999999999;
  transition: transform 0.3s cubic-bezier(.57, .21, .69, 1.25);

  &--close {
    transform: translateY(50px);
  }

  @include Xsmall {
    height: 80px;

    &--close {
      transform: translateY(80px);
    }
  }

  &__text {
    color: white;
    font-size: 12px;

    @include Xsmall {
      width: 80%;
    }
  }

  &__link {
    color: white;
    margin-left: 5px;
    font-size: 12px;
  }

  &__btn {
    width: 35px;
    height: 25px;
    font-size: 12px;
    color: var(--color-text);
    font-weight: 600;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 1rem;
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;

    @include Xsmall {
      margin-left: 0;
    }
  }
}