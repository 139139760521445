
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.home {
  background-size: 60% 28%;
  background-repeat: no-repeat;
  background-position: bottom 3% left;
  overflow: hidden;
  position: relative;
  height: 100%;

  @include medium {
    padding-bottom: 2rem;
  }


  &::after {
    content: "";
    background: radial-gradient(circle,
        rgba(3, 29, 48, 0.06) 0%,
        rgba(3, 29, 48, 1) 88%),
      url('../img/logo-fd-code.svg');
    background-size: 35%;
    background-repeat: no-repeat;
    background-position: left 14% bottom 76%;
    width: 100%;
    height: 500px;
    max-width: 1300px;
    margin: 0 auto;
    position: absolute;
    left: -100px;
    z-index: 1;
    bottom: -176px;

    @include medium {
      display: none;
    }
  }

  &__main {
    display: flex;
    gap: 2rem;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;

    @include medium {
      flex-direction: column;
      max-width: unset;
    }

    .add-home--left {
      left: 20px;

      @include medium {
        left: unset;
      }
    }

    .add-home--right {
      right: 20px;

      @include medium {
        right: unset;
      }
    }
  }

  &__separatorBottom {
    margin-bottom: 8rem;
  }
}