
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.BestGame {
  max-width: 900px;
  margin: 0 auto;
  color: var(--color-text);
  z-index: 500;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  min-height: 405px;

  @include Xsmall {
    margin-top: 0;
  }
}

.BestGame__title {
  width: fit-content;
  margin: 0 auto;
  font-size: 23px;
  text-align: center;
  color: whitesmoke;
}

.BestGame__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;

  @include small {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
  }

  @include Xsmall {
    flex-direction: column;
    align-items: center;
  }
}