
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.HomeBanner {
  position: relative;
  z-index: 2;
  background-color: var(--color-back);
  width: 100vw;

  @include small {
    height: 55vh;
  }

  @include Xsmall {
    height: 63vh;
  }

}

.HomeBanner__wrapper {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle,rgba(3, 29, 48, 0) 0%,rgba(3, 29, 48, 1) 82%),url('../../../img/bg-gamepad.webp');
  background-size: 71%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 2500px;
  margin: 0 auto;
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background: linear-gradient(180deg,rgba(3, 29, 48, 0) 0%,rgba(3, 29, 48, 1) 82%);
    width: 100%;
    height: 20%;
  }

  @include medium {
    background-size: 130%;
  }

  @include small {
    height: 55vh;
    padding: 80px 0 50px 0px;
  }

  @include Xsmall {
    height: 60vh;
    background-size: 146% 96%;
    background-position: top;
    background-image: radial-gradient(circle,
        rgba(3, 29, 48, 0) 0%,
        rgba(3, 29, 48, 1) 82%),
      url('../../../img/bg-gamepade-mobile.webp');

    &::before {
      height: 60%;
    }
  }
}

.HomeBanner__title {
  color: whitesmoke;
  text-align: center;
  font-size: 30px;
  z-index: 500;
  margin: 3rem 0;

  @include Xsmall {
    font-size: 26px;
    padding: 2rem 0;
  }
}

.HomeBanner__btn {
  z-index: 80000;
  margin-bottom: 3rem;

  @include Xsmall {
    margin-bottom: 2rem;
  }
}