
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.Container {
    padding: 0 2rem;

    @include medium {
        padding: 0 1rem;
    }

    @include small {
        padding: 0 10px;
    }
}