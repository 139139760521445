
    @import "mediaQueries.scss"; 
    @import "animation.scss";
.BestGameCard {
  position: relative;
  text-decoration: none;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;
  border-radius: 30px 30px 10px 10px;
  overflow: hidden;
  color: whitesmoke;
  cursor: pointer;
  box-shadow: rgba(230, 230, 230, 0.35) 0px 5px 15px;
  @include fadeIn;
  max-width: 420px;

  @include small {
    &:last-child {
      grid-column: 1 / -1;
    }
  }

  &:hover {
    bottom: 10px;
  }
}

.BestGameCard__img {
  width: 100%;
  height: auto;
  border-radius: 28px 28px 0 0;
  height: 209px;
  background-position: center;
  background-size: cover;
}

.BestGameCard__textContainer {
  position: relative;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  padding: 3px;
  width: 100%;
  height: 115px;

  @include small {
    height: 140px;
  }

  @include Xsmall {
    height: 95px;
  }

  svg {
    position: absolute;
  }
}

.BestGameCard__text {
  border-radius: 0 0 10px 10px;
  padding: 8px;
  position: relative;
  top: 0;
  height: 100%;
  background: rgba(1, 53, 121, 0.2);
  background: radial-gradient(circle,
      rgba(3, 29, 48, 0) 10%,
      rgba(3, 29, 48, 1) 92%);
}

.BestGameCard__circle {
  border-radius: 50%;
  filter: blur(20px);
  -webkit-filter: blur(20px);
  position: absolute;
}

.BestGameCard__circle1 {
  width: 188px;
  height: 150px;
  background-color: #6f54e9;
  top: 66px;
  left: -25px;
}

.BestGameCard__circle2 {
  width: 211px;
  height: 150px;
  top: -41px;
  right: -26px;
  background-color: #6f54e9;
}

.BestGameCard__circle3 {
  background: #00c3ff;
  width: 211px;
  height: 150px;
  top: 70px;
  right: -50px;
}

.BestGameCard__circle4 {
  width: 177px;
  height: 150px;
  background-color: #00c3ff;
  top: -51px;
  left: -50px;
}

.BestGameCard__description {
  font-size: 12px;
  width: 100%;
  margin-top: 0.5rem;
}

.BestGameCard__title {
  font-size: 14px;
  cursor: pointer;
}