@mixin xlUp {
    @media (min-width: 1680px) {
        @content;
    }
}



@mixin medium {
    @media (max-width: 1250px) {
        @content;
    }
}

@mixin small {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin Msmall {
    @media (max-width: 800px) {
        @content;
    }
}


@mixin Xsmall {
    @media (max-width: 530px) {
        @content;
    }
}